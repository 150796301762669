import { Button as MUIButton } from "@mui/material";

const Button = ({
  onClick,
  children,
  disabled,
  customStyles,
  isLoading,
  isSubmittableEnter,
  ...props
}) => {
  return (
    <MUIButton
      onSubmit={isSubmittableEnter ? onClick : null}
      type={isSubmittableEnter ? "submit" : "button"}
      onClick={onClick}
      disabled={disabled}
      style={{
        display: "inline-block",
        fontFamily: "Gilroy",
        borderRadius: 20,
        ...customStyles,
      }}
      {...props}
    >
      <span style={{ fontFamily: "Gilroy-Bold" }}>{children}</span>
    </MUIButton>
  );
};

export default Button;
