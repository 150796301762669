import React, { useState, useEffect, useContext } from "react";
import { CircularProgress } from "@mui/material";
import { Button, Input } from "../../ui/index";
import {
  cleanSessionCookie,
  SessionContext,
  setSessionCookie,
} from "../../helpers/session";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/userService";
import useMobile from "../../hooks/useOrientation";
import darkLogo from "../../assets/dark-logo.png";
import { useDispatch } from "react-redux";
import { addUser } from "../../store/actions/userActions";

import { processErrorResponse } from "../../helpers/processErrorResponse";
import Bugsnag from "@bugsnag/browser";

const APP_VERSION = process.env.REACT_APP_VERSION;

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isMobile = useMobile();
  const { setUser } = useContext(SessionContext);

  const styles = {
    container: {
      display: "flex",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#2D2D2D",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      width: isMobile ? "90%" : "30%",
    },
    loginButton: {
      alignSelf: "center",
      margin: 20,
      width: 200,
      fontFamily: "Gilroy",
    },
    errorMessage: {
      marginTop: 20,
      color: "white",
      alignSelf: "center",
    },
  };

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    cleanSessionCookie();
  }, []);

  const handleChangeForm = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmitLogin = () => {
    setLoading(true);
    login(formData.username.toLocaleLowerCase(), formData.password)
      .then((res) => {
        setLoading(false);
        setUser(res);
        dispatch(addUser(res));
        setSessionCookie(res.token, res);
        Bugsnag.notify("Login.", function (event) {
          event.severity = "info";
          event.addMetadata("userData", {
            user: formData.username,
            isPasswordInsecure: res.phone === formData.password,
            version: APP_VERSION,
          });
        });
        navigate("/perfil", {
          state: { isPasswordInsecure: res.phone === formData.password },
        });
      })
      .catch((e) => {
        setLoading(false);
        setMessage(processErrorResponse(e)[1]);
      });
  };

  const ShowPasswordButton = () => {
    return (
      <span
        style={{
          color: "white",
          margin: 0,
          width: "100%",
          alignSelf: "center",
          textAlign: "center",
          fontSize: 12,
        }}
        className="showPassword"
        onClick={() => setPasswordVisible(!passwordVisible)}
      >
        {passwordVisible ? "Ocultar password" : "Mostrar password"}
      </span>
    );
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleSubmitLogin();
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.form}>
        <img
          style={{
            width: 100,
            position: "absolute",
            top: 50,
            alignSelf: "center",
          }}
          src={darkLogo}
          alt=""
        ></img>
        <h2 style={{ alignSelf: "center", color: "white" }}>
          Sitio en mantenimiento.
        </h2>
        <h4 style={{ alignSelf: "center", color: "white" }}>
          Disculpe las molestias.
        </h4>
        {/* <Input
          onChange={(val) => handleChangeForm("username", val.target.value)}
          placeholder="Usuario"
          style={{ margin: 10 }}
          variant="dark-mode"
        ></Input>
        <Input
          type={passwordVisible ? "default" : "password"}
          onChange={(val) => handleChangeForm("password", val.target.value)}
          placeholder="Password"
          style={{ margin: 10 }}
          variant="dark-mode"
          onKeyDown={handleKeypress}
        ></Input>
        <ShowPasswordButton />
        <Button
          isSubmittableEnter
          variant="contained"
          disabled={loading}
          onClick={handleSubmitLogin}
          style={styles.loginButton}
        >
          LOGIN
        </Button>
        <Button
          variant="dark-mode"
          onClick={() => navigate("/registro")}
          isSubmittableEnter
        >
          REGISTRARSE
        </Button>
        <Button variant="dark-mode" onClick={() => navigate("/recuperar")}>
          Olvidé mi contraseña
        </Button> */}
        {loading ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : null}
        <h3 style={styles.errorMessage}>{message}</h3>
      </div>
    </div>
  );
};

export default LoginScreen;
