import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { getUserTrips } from "../../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PaymentHistory from "../payment/PaymentHistory";
import { getPassengerById } from "../../services/passengerService";
import { processErrorResponse } from "../../helpers/processErrorResponse";
import { Select, Button } from "../../ui/index";
import { useSelector } from "react-redux";
import useMobile from "../../hooks/useOrientation";
import { getPaymentHistory } from "../../services/paymentService";
import { CenteredLoader } from "../shared/CenteredLoader";
import { PassengerTripDetails } from "../payment/PassengerTripDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { getSessionCookie, SessionContext } from "../../helpers/session";
import { jwtDecode } from "jwt-decode";
import Bugsnag from "@bugsnag/browser";

library.add(faPlus);

const MyTrips = () => {
  const [trips, setTrips] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState();
  const [preSelectedTripIndex, setPreSelectedTripIndex] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const p = state?.p; //passenger recieved from params
  const { user } = useContext(SessionContext);
  const id = user?.id;
  const { passengers } = useSelector((state) => state.passengers);
  const isMobile = useMobile();

  useEffect(() => {
    setIsLoading(true);
    Bugsnag.notify("Ver mis viajes.", function (event) {
      event.severity = "info";
      const { UserID, Name, LastName, Email } = jwtDecode(getSessionCookie());
      event.setUser(UserID, Email, Name + " " + LastName);
    });
    if (id) {
      getUserTrips(id)
        .then((res) => {
          setTrips(res);
          if (res.length === 1) {
            setSelectedTrip(res[0]);
          }
          let preselectedIndex;
          if (p) {
            res.forEach((res, index) => {
              if (res.passengerId === p.id) {
                preselectedIndex = index;
              }
            });
          }
          if (preselectedIndex) {
            setPreSelectedTripIndex(preselectedIndex);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setError(processErrorResponse(e)[1]);
        });
    }
  }, []);

  useEffect(() => {
    if (preSelectedTripIndex) {
      handleChangeTrip(preSelectedTripIndex);
    }
  }, [preSelectedTripIndex]);

  const handleChangeTrip = (passengerId) => {
    if (trips) {
      const trip = trips.find((t) => t.passengerId === passengerId);
      setSelectedTrip(trip);
    } else {
      console.log("Error encontrando viajes");
    }
  };

  const handleAddNewPassenger = (id) => {
    navigate(`/registro`);
  };

  const getOptionsFromTrips = (passengers) => {
    let options = [{ key: 0, label: "Seleccionar un pasajero" }];
    passengers.forEach((pass, index) => {
      options.push({
        key: pass.id,
        label: pass.name + ": " + pass.programName + " - " + pass.tripName,
      });
    });
    return options;
  };

  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    container: {
      width: isMobile ? "90vw" : "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    addTripButton: {
      marginTop: 10,
      marginBottom: 10,
    },
  };

  return (
    <div style={styles.wrapper}>
      {isLoading ? (
        <div style={styles.container}>
          <CircularProgress />
        </div>
      ) : error ? (
        <h3>{error}</h3>
      ) : (
        <div style={{ padding: 10 }}>
          <div style={styles.titleRow}>
            <h1>Mis viajes</h1>
            {/* <Button
              style={styles.addTripButton}
              onClick={() => handleAddNewPassenger()}
            >
              <FontAwesomeIcon icon="plus" style={{ marginRight: 10 }} />
              Agregar viaje
            </Button> */}
          </div>
          <>
            <Select
              onChange={(val) => handleChangeTrip(val.target.value)}
              defaultValue={"Elegir viaje"}
              options={passengers && getOptionsFromTrips(passengers)}
              placeholder={"Elegir un viaje"}
              label="Elegir un viaje"
              style={{ width: isMobile ? "80%" : 600 }}
            />
          </>
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        {selectedTrip && <SelectedTrip trip={selectedTrip} />}
      </div>
    </div>
  );
};

export default MyTrips;

const SelectedTrip = ({ trip }) => {
  const isMobile = useMobile();
  const navigate = useNavigate();

  const styles = {
    rowContainer: {
      paddingLeft: isMobile ? 0 : 10,
      flex: 1,
      width: "100%",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
    },
    statusRow: {
      paddingRight: isMobile ? 0 : 20,
      flex: 1,
    },
    tripDataContainer: { display: isMobile ? "block" : "flex", flex: 1 },
    paymentHistoryContainer: {
      flex: 1,
      paddingLeft: isMobile ? 0 : 10,
      paddingTop: 15,
    },
  };
  const [passenger, setPassenger] = useState();
  const [paymentHistory, setPaymentHistory] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setPaymentHistory();
    setPassenger();
    getPassenger();
    if (trip) {
      getPaymentHistory(trip.passengerId)
        .then((res) => {
          setPaymentHistory(res);
        })
        .catch((e) => {
          processErrorResponse(e);
        });
    }
  }, [trip]);

  const getPassenger = async () => {
    getPassengerById(trip.passengerId).then((res) => {
      setPassenger(res);
    });
  };

  if (!trip) {
    return (
      <>
        <CenteredLoader />
      </>
    );
  }
  return (
    <>
      <div style={{ padding: 10 }}>
        {passenger && paymentHistory ? (
          <>
            <div style={styles.rowContainer}>
              <div style={styles.tripDataContainer}>
                <PassengerTripDetails
                  setFinishedLoading={() => setLoading(false)}
                  passenger={passenger}
                ></PassengerTripDetails>
              </div>
              {!isMobile && <div style={{ flex: 1 }}></div>}
            </div>
            {/* <Button onClick={() => navigate("/habitaciones")}>
              CREAR HABITACION
            </Button> */}
            {!loading && (
              <div style={styles.paymentHistoryContainer}>
                <PaymentHistory
                  paymentHistory={paymentHistory}
                ></PaymentHistory>
                {!isMobile && <div style={{ flex: 1 }}></div>}
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
