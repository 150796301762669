// Encryption function
function encrypt(x) {
  return (x * 123 + 456) % 997;
}

// Decryption function (if you know the modulus, multiplier, and increment)
function decrypt(encryptedValue) {
  // Find the modular multiplicative inverse of the multiplier
  let inverse = 1;
  while ((123 * inverse) % 997 !== 1) {
    inverse++;
  }
  return ((encryptedValue - 456 + 997) * inverse) % 997;
}

export const encrypted = (number) => encrypt(number, 997, 123, 456);

export const decrypted = (encrypted) => decrypt(encrypted, 997, 123, 456);
